<template>
   <section class="boutpart container mt-3 h-100">
      <!-- MODIFY -->
      <div  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page'>
          
            <div class="section_header" @click="move_to('/book/boutchoose'+'?t='+$root._SYS().add_v())">
                <i class='mdi mdi-arrow-left mr-3'></i>
                <p>Back to chapters</p>
            </div>
            
          
            <div class="mt-5">
               <template v-for="episod in cdata.business_logic.a_part.episods">
                         <div class='chapter_choose_outer mb-3' v-for="(chapter, chapter_index) in episod.chapters" :key="chapter_index">

                             <div v-if="state['unlocked_chapters'][chapter.chapter_qr] == 'unlocked'" class="lvl_cover_element" style="position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2; cursor: pointer;" @click="move_to('/book/boutstory?qr='+chapter.chapter_qr+'&back=DECODE_BACK_LINK_AND_IDS_TO_ACCSEPT_OR_SUCCESS_QUEST'+'&v='+$root._SYS().add_v())"></div>

                            <p :class="(state['unlocked_chapters'][chapter.chapter_qr] == 'unlocked') ? 'active' : ''">
                               {{ chapter.chapter_name}}
                            </p>
                            
                            <i v-if="state['unlocked_chapters'][chapter.chapter_qr] == 'unlocked'" class='mdi mdi-chevron-right'></i>
                            <i v-else class='mdi mdi-lock-outline'></i>
                         </div>
               </template>
            </div>
      </div>
      <div v-else>Loading...</div>
   </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import moment from 'moment'
    import 'moment/locale/ru'; //moment.locale('ru');
    import store from '@/store/index.js';
    var cdata = {//Если не указать - не всключиться реактивность 
        /* COPYPASTE MODIFY */
        system: {
            _route_: "book_boutpart", /* MODIFY */
            is_section: {// store/index  -> is_required_sections
                center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
            },
            is_stage: {// store/index  -> is_required_stages
                center: false,
            },
            
            cnf: {},
            buttons: {
                common: {
                    is_button_blocked: true,
                    button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                },
                common_without_symmary: {
                    is_button_blocked: true,
                    button_blocking_exeption_list:
                            {
                                0: "json_version", // json_version
                            }
                },
                button1: {
                    is_button_blocked: true,
                    button_blocking_exeption_list:
                            {
                                0: "json_version", //json_version  
                            }
                },

            },

        },
        errors: {
            is_errors: false,
            actions_error: {},
        },
        business_logic: {
            json_catalog: {},
        },

    }

    export default {
        name: 'boutpart', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },

        created() { },

        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted__book(this.$store, this.cdata); //обнулить флажки

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);

        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);


                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */
                    state['state_books'] = this.$store.state['state_tables'].state_books;/* MODIFY */
                    
                    state['unlocked_chapters'] = this.$root._LAYOUT().check_unlocked_chapters(this.$store, this.cdata);

                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },

            form() {
                let form = {};// form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['is_all_fin_for_this_page'] = this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page;
                form['is_all_fin_for_this_page_with_additional'] = this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page_with_additional;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";

                return form;
            }
            /* #ODIFY */
            /* #OPYPASTE */

        },

        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: async function () {



                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                        if (
                            this.$store.state.system[this.cdata.system._route_].is_mounted_execute &&
                            this.$store.state.is_all_stage_fin && 
                            !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page_executed && 
                            !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page
                        ) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени) 
                        this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_executed', {'is_all_fin_for_this_page_executed': true, '_route_': this.cdata.system._route_});


                        console.log("YESSSSSSSSSSS");
                        //this.cdata.business_logic.user_name = ""; 
                        //this.$root._AUTH_LOGIC().set_a_users_for_select(this.$store, this.cdata);

                        var url = new URL(window.location.href);
                        this.cdata.business_logic.serie_code = url.searchParams.get("serie_code");
                        this.cdata.business_logic.part_code = url.searchParams.get("part_code");

                        //this.$root._AUTH_LOGIC().set_a_part_by_serie_code_and_part_code(this.$store, this.cdata);
                         this.cdata.business_logic['a_part'] = JSON.parse(JSON.stringify(this.$store.state.state_tables.state_books.json_catalog.series[this.cdata.business_logic.serie_code].parts[this.cdata.business_logic.part_code]));


                        this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page', {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_});
                        //this.cdata.system.is_all_fin_for_this_page = true;
                        /*  проверить локалсторадж версию страницы....*/

                        this.$root._SYS().page_v_update(this.$store, this.cdata);

                        console.log("REGISTER ALL TABLE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {}); 
                    }


                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

        },

        methods: {

            make_cryptotoken: function (acid, uid, server_action, activ_uid) {

                let cryptotoken = {
                    acid: acid,
                    uid: uid,
                    server_action: server_action,
                    activ_uid: activ_uid,

                };

                let ccryptotoken = window._CRYPT().encrypt_json(cryptotoken);
                return ccryptotoken;
            },

            is_selected_uid: function (uid) {

                try {

                    if (uid === this.$store.state.state_tables.state_auth.r_user.uid)
                    {
                        return true;
                    }
                } catch (ex) {
                    console.log(ex);
                    console.log("ПУСТОЙ АКТИВНЫЙ ПОЛЬЗОВАТЕЛЬ!!!");
                }

                return false;
            },

         
            
        

            move_to: function (new_route) {
//                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
                this.$router.push(new_route);
            },

           
            move_to_bqr: function (new_route) {
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
            },
            
            
            

        }


    }
</script>

<style lang="scss" scoped>
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: 0px !important;
        }
    }
    
    
    .section_header {
        & i,
        & p {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
        }

        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .chapter_choose_outer {
        cursor: pointer;
        position: relative;
        background-color: #ffffff;
        padding: 5px 16px;
        border-radius: 12px;
        
        display:flex;
        justify-content: space-between;
        align-items: center;

        & p {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            
            display:flex;
            align-items: center;
            height: 37px;
            transition: 0.5s;
        }
        
        & i {
            font-size: 42px;
        }
        
        &:hover p.active{
             font-size: 24px;
             transition: 0.5s;
        }
    }
</style>


